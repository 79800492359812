/* You can add global styles to this file, and also import other style files */

$font-size: 16px;
$pta-green-color: #8fdc4f;

html,
body {
    height: 100%;
    overflow-x: hidden;
    background: #fff;
    font-size: $font-size;
}
@font-face {
    font-family: OpenSans;
    src: url(assets/fonts/OpenSans-Light.ttf);
}
@font-face {
    font-family: mainBold;
    src: url(assets/fonts/OpenSans-ExtraBold.ttf);
}
@font-face {
    font-family: lightBold;
    src: url(assets/fonts/open-sans.bold.ttf);
}
@font-face {
    font-family: letterbold;
    src: url(assets/fonts/OpenSans-Regular.ttf);
}
@font-face {
    font-family: tabheads;
    src: url(assets/fonts/OpenSans-SemiBold.ttf);
}
body,
input,
textarea,
select,
button,
p,
h1,
h2,
h3,
h4,
h5,
label,
div {
    font-family: Open Sans;
}
.p-text {
    padding-top: 0.75rem !important;
    padding-bottom: 2rem !important;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: $font-size;
    color: #000000;
}
.header {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 125%;
    padding-top: 48px;
    // letter-spacing: -0.03em;
    color: #000000;
}
label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: $font-size;
    color: #000000;
}
.textInput {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800 !important;
    font-size: $font-size;
    color: #000000;
    caret-color: #000000;
}
.individualbody {
    margin: 0 auto;
    width: 66.25vw;
    padding-bottom: 16px;
    margin-bottom: 100px;
}
.btnsave {
    margin: 0px 20px 15px 20px;
    background: $pta-green-color;
    color: #000;
    border-radius: 5px;
    height: 40px;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}
.btnremove {
    margin: 0px 20px 15px 20px;
    background: rgb(226, 103, 103);
    color: #fff;
    border-radius: 5px;
    height: 40px;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}
.backbtn {
    background: #fff !important;
    color: #000 !important;
    box-shadow: none !important;
    border: 1px solid #000 !important;
}
.submitbtn {
    margin: 0px 15px !important;
    box-shadow: none !important;
}
.mat-table {
    width: 100%;
    font-family: "Open Sans" !important;
}
td.mat-cell {
    white-space: normal;
    word-wrap: break-word;
    max-width: 150px;
}
.mat-option {
    font-family: "Open Sans" !important;
}
.mat-option-text {
    display: inline-block;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "letterbold" !important;
}
.mat-card-subtitle {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #000000;
}
.mat-checkbox {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: $font-size;
    color: #000000;
}
.mat-menu-item {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: $font-size;
    color: #000000;
}
.error-msg {
    background: rgb(226, 103, 103);
    color: #fff;
}
.error-msg .mat-simple-snackbar-action {
    background: rgb(226, 103, 103);
    color: #fff;
}
.pagination-color {
    background-color: #000;
}
.mat-form-field-ripple {
    background-color: #000 !important;
}
.custom-icon {
    cursor: pointer;
}
.custom-form-field-icon {
    position: absolute;
    right: 0;
    color: #000000;
}
.mat-select-ripple {
    background-color: #000 !important;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
    color: #000 !important;
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: #000 !important;
}


// mat-checkbox

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $pta-green-color;
}

.mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
    background-color: #8fdc4f !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: unset;
}

.mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background-color: $pta-green-color;
}

// mat-radio-button

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #8fdc4f !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    color: #8fdc4f !important;
    background-color: #8fdc4f !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: #8fdc4f !important;
}

// mat-slide-toggle

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: $pta-green-color;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #d9f5c3;
}

// mat-tab

.mat-tab-label {
    padding: 0px !important;
    min-width: 100px !important;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $pta-green-color;
}
